import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Refund Policy">
    <section className="section-refund-policy">
      <div className="content_fullwidth">
        <div className="one_full">
          <p>We believe in fair policy! We endeavor to do everything to resolve the technical problems that you are facing and that are preventing you from getting most out of the services offered. We are always here for you and we are proud of our expert professionals who are at your service round-the-clock.</p>
          <div className="clearfix mar_top3"></div>
          <p>We ensure and guarantee the services being provided and the quality of the solution listed on the website https://ddzone.xyz are up to the mark. Our expert professionals at classicists assure support and satisfaction in terms of the technical services offered. </p>
          <div className="clearfix mar_top3"></div>
          <p>However in case you are not satisfied with the services being provided please reach us on our toll free number +1 (786) 929-9663 or email us at support@dd.zone, we promise and guarantee an immediate remedy to the problem, if any within 12 hours from the time of complaint throughout the service duration. </p>
          <div className="clearfix mar_top3"></div>
          <p>We ensure the services up to your satisfaction and if in case you want to terminate our services, Tech Assist offers refund to all its customers who has taken services and wants to terminate the same within 7 days, post that the refund or termination will be on a pro-rate basis for the service that are classified under the subscription. </p>
          <div className="clearfix mar_top3"></div>
          <p>Beyond the fact that issue was not provided with a satisfactory resolution, please mention the reason for the refund or termination and under such request a reply will be sent via a registered email address and an intimation via the phone as well after it has been determined that a refund/return is necessary. </p>
          <div className="clearfix mar_top3"></div>
          <p>In case you (customer) is not satisfied with the services/product provided, the commitments made and wants an immediate return, the same will be returned to the credit card or bank account. </p>
          <div className="clearfix mar_top3"></div>
          <p>We don’t store credit card information for any customer and we would not do any charging without your explicit consent. You can move from a monthly plan to an incident based plan, and we would be able to accommodate your amount for any such move at the terms and conditions suggested to you by your account manager at that time. </p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Right to Refuse, Suspend or Terminate Service </h4>
          <p>We reserve the right to refuse, terminate, or suspend service to any user for any reason we deem, in our sole discretion, to do so, without any prior notice. Typically, we will suspend, terminate or refuse service if we determine that a user is using a computer system in an improper or illegal manner, providing indecent or obscene content, violating the rights of privacy or publicity, violating the civil rights of others, conducting actions that defame a third party or entity, are publishing “hate” or intolerant material, or are threatening or harassing others. However, we may also suspend, refuse, or terminate service for any reason that we, in our sole discretion, consider appropriate to do so. </p>
          <div className="clearfix mar_top3"></div>
          <p><strong>Note: </strong>DD Zone, Inc. is always there to help you in different ways. We appreciate that before filing any issue directly from bank kindly give us a call or email to make the final settlement on mutual understanding.</p>
        </div>
        <div className="clearfix mar_top3"></div>
      </div>


    </section>
  </Layout>
);

export default Page;

